@import url("resets.css");

html {
  background-color: rgb(249, 218, 180);
}

body {
  font-family: "Lato", sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}

nav {
  background-color: orangered;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-right: 30px;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, rgb(242, 98, 2), rgb(193, 190, 107)) 1;
}

.play-btn {
  padding: 0.5rem 2.5rem;
  background-color: orange;
  font-size: 2rem;
  font-weight: 900;
  font-style: normal;
  color: black;
  border-radius: 5px;
  border: solid 1px black;
  box-shadow: 5px 5px 5px rgb(62, 60, 60);
}

.play-btn:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

#main-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: black;
}

.disabled {
  pointer-events: none;
  opacity: 0.1;
  z-index: 1000; /* z-index: 1000 to ensure it appears on top of other elements. Adjust this value if you have other elements with high z-index values.  */
}

#hero {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#hero img {
  /* width: 60%;
  height: 60%; */
  max-width: 100%;
  height: auto;
  align-self: center;
}

#title {
  font-family: "Montserrat Subrayada", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: bisque;
}

#subtitle {
  font-weight: 900;
  font-size: 2rem;
  padding-bottom: 0.5rem;
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(to right, yellow, red);
}

#landing-page-subsection {
  font-style: normal;
  display: flex;
  flex-direction: column;
}

.subsection-container-zero {
  background-color: bisque;
  color: black;
}

.black-bold {
  font-weight: 800;
}

#gray {
  color: black;
}

.extra-margin {
  margin-top: 10px;
  margin-bottom: 15px;
}

.subsection-container-one,
.subsection-container-two {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-evenly;
  padding: 4rem;
  border-top-width: 10px;
  border-top-style: solid;
  border-image: linear-gradient(to right, rgb(242, 98, 2), darkorchid) 1;
  background-image: linear-gradient(white, rgb(181, 177, 177));
}

.subsection-container-one img,
.subsection-container-two img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.subsection-container-one img {
  box-shadow: 5px 5px 5px gray;
}

.subsection-container-two img {
  box-shadow: 5px 5px 5px gray;
}

.subsection-container-one-text,
.subsection-container-two-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: black;
  font-weight: 400;
  border: solid 1px rgb(176, 171, 171);
  border-radius: 10px;
  padding: 7px 5px;
}

.subsection-container-text-subheading {
  font-weight: 700;
  margin-bottom: 10px;
}

.decorative-text {
  color: white;
  background-image: linear-gradient(to right, rgb(242, 98, 2), darkorchid);
  border-radius: 5px;
  padding: 2px;
}

.subsection-container-three {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: bisque;
  border-top-width: 5px;
  border-top-style: solid;
  border-image: linear-gradient(to right, rgb(242, 98, 2), darkorchid) 1;
}

.subsection-container-three-text {
  font-weight: 700;
  padding-bottom: 1.5rem;
}

.subsection-container-three-text p {
  color: transparent;
  background-image: linear-gradient(to right, black, orangered, yellow);
  background-clip: text;
}

footer {
  padding: 20px;
  background-color: antiquewhite;
  border-top: solid 1px gray;
  font-weight: 300;
}

span {
  color: orangered;
}

/* media queries */

@media (max-width: 3000px) {
  .subsection-container-zero {
    font-size: 2em;
    padding: 15px 15px;
  }

  .subsection-container-one,
  .subsection-container-two {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
  }

  .subsection-container-one-text,
  .subsection-container-two-text {
    font-size: 2rem;
  }

  .subsection-container-three p {
    font-size: 3.5rem;
  }

  footer {
    font-size: 1.25em;
  }
}

@media (max-width: 2000px) {
  .subsection-container-zero {
    font-size: 1.5em;
    padding: 15px 15px;
  }

  .subsection-container-one,
  .subsection-container-two {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
  }

  .subsection-container-one-text,
  .subsection-container-two-text {
    font-size: 1.5rem;
  }

  .subsection-container-three p {
    font-size: 3rem;
  }

  footer {
    font-size: 1.25em;
  }
}

@media (max-width: 800px) {
  #title,
  #subtitle {
    font-size: 1.5rem;
  }

  /* #hero img {
    width: 70%;
    height: 70%;
  } */

  .subsection-container-zero {
    font-size: 1em;
    padding: 15px 15px;
  }

  .subsection-container-one-text,
  .subsection-container-two-text {
    font-size: 1rem;
  }

  .subsection-container-three p {
    font-size: 2rem;
  }

  .subsection-container-three {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .play-btn {
    font-size: 1.5rem;
  }

  footer {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  #title,
  #subtitle {
    font-size: 1em;
  }

  /* #hero img {
    width: 90%;
    height: 90%;
  } */

  .subsection-container-zero {
    font-size: 1em;
    padding: 15px 10px;
  }

  .subsection-container-one-text p,
  .subsection-container-two-text p {
    font-size: 1em;
    width: 250px;
  }

  .subsection-container-three p {
    font-size: 1.5rem;
  }

  .subsection-container-three {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  nav {
    text-align: center;
    padding-right: 0;
  }

  .play-btn {
    font-size: 1rem;
  }

  footer {
    font-size: 0.5em;
  }
}
