*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

html {
  background-color: #f9dab4;
}

body {
  max-width: 1200px;
  margin: 0 auto;
  font-family: Lato, sans-serif;
}

nav {
  text-align: right;
  background-color: #ff4500;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-image: linear-gradient(to right, #f26202, #c1be6b) 1;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-right: 30px;
}

.play-btn {
  color: #000;
  background-color: orange;
  border: 1px solid #000;
  border-radius: 5px;
  padding: .5rem 2.5rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  box-shadow: 5px 5px 5px #3e3c3c;
}

.play-btn:hover {
  color: #000;
  cursor: pointer;
  background-color: #fff;
}

#main-container {
  text-align: center;
  background-color: #000;
  flex-direction: column;
  display: flex;
}

.disabled {
  pointer-events: none;
  opacity: .1;
  z-index: 1000;
}

#hero {
  text-align: center;
  flex-direction: column;
  align-content: center;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

#hero img {
  align-self: center;
  max-width: 100%;
  height: auto;
}

#title {
  color: bisque;
  font-family: Montserrat Subrayada, sans-serif;
  font-style: normal;
  font-weight: 700;
}

#subtitle {
  color: #0000;
  background-image: linear-gradient(to right, #ff0, red);
  background-clip: text;
  padding-bottom: .5rem;
  font-size: 2rem;
  font-weight: 900;
}

#landing-page-subsection {
  flex-direction: column;
  font-style: normal;
  display: flex;
}

.subsection-container-zero {
  color: #000;
  background-color: bisque;
}

.black-bold {
  font-weight: 800;
}

#gray {
  color: #000;
}

.extra-margin {
  margin-top: 10px;
  margin-bottom: 15px;
}

.subsection-container-one, .subsection-container-two {
  background-image: linear-gradient(#fff, #b5b1b1);
  border-top-style: solid;
  border-top-width: 10px;
  border-image: linear-gradient(to right, #f26202, #9932cc) 1;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  padding: 4rem;
  display: flex;
}

.subsection-container-one img, .subsection-container-two img {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
  box-shadow: 5px 5px 5px gray;
}

.subsection-container-one-text, .subsection-container-two-text {
  color: #000;
  border: 1px solid #b0abab;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  padding: 7px 5px;
  font-weight: 400;
  display: flex;
}

.subsection-container-text-subheading {
  margin-bottom: 10px;
  font-weight: 700;
}

.decorative-text {
  color: #fff;
  background-image: linear-gradient(to right, #f26202, #9932cc);
  border-radius: 5px;
  padding: 2px;
}

.subsection-container-three {
  background-color: bisque;
  border-top-style: solid;
  border-top-width: 5px;
  border-image: linear-gradient(to right, #f26202, #9932cc) 1;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.subsection-container-three-text {
  padding-bottom: 1.5rem;
  font-weight: 700;
}

.subsection-container-three-text p {
  color: #0000;
  background-image: linear-gradient(to right, #000, #ff4500, #ff0);
  background-clip: text;
}

footer {
  background-color: #faebd7;
  border-top: 1px solid gray;
  padding: 20px;
  font-weight: 300;
}

span {
  color: #ff4500;
}

@media (width <= 3000px) {
  .subsection-container-zero {
    padding: 15px;
    font-size: 2em;
  }

  .subsection-container-one, .subsection-container-two {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .subsection-container-one-text, .subsection-container-two-text {
    font-size: 2rem;
  }

  .subsection-container-three p {
    font-size: 3.5rem;
  }

  footer {
    font-size: 1.25em;
  }
}

@media (width <= 2000px) {
  .subsection-container-zero {
    padding: 15px;
    font-size: 1.5em;
  }

  .subsection-container-one, .subsection-container-two {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .subsection-container-one-text, .subsection-container-two-text {
    font-size: 1.5rem;
  }

  .subsection-container-three p {
    font-size: 3rem;
  }

  footer {
    font-size: 1.25em;
  }
}

@media (width <= 800px) {
  #title, #subtitle {
    font-size: 1.5rem;
  }

  .subsection-container-zero {
    padding: 15px;
    font-size: 1em;
  }

  .subsection-container-one-text, .subsection-container-two-text {
    font-size: 1rem;
  }

  .subsection-container-three p {
    font-size: 2rem;
  }

  .subsection-container-three {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .play-btn {
    font-size: 1.5rem;
  }

  footer {
    font-size: 1em;
  }
}

@media (width <= 480px) {
  #title, #subtitle {
    font-size: 1em;
  }

  .subsection-container-zero {
    padding: 15px 10px;
    font-size: 1em;
  }

  .subsection-container-one-text p, .subsection-container-two-text p {
    width: 250px;
    font-size: 1em;
  }

  .subsection-container-three p {
    font-size: 1.5rem;
  }

  .subsection-container-three {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  nav {
    text-align: center;
    padding-right: 0;
  }

  .play-btn {
    font-size: 1rem;
  }

  footer {
    font-size: .5em;
  }
}
/*# sourceMappingURL=index.e827e041.css.map */
